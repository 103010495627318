/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountTabListItem {
    &:last-child {
        @include desktop {
            display: none;
        }
    }

    &-Button {
        font-size: 16px;
        /* stylelint-disable-next-line number-max-precision */
        letter-spacing: 0.5px;

        @include mobile {
            font-size: 14px;
        }
    }

    &_isActive {
        .MyAccountTabListItem-Button {
            text-decoration: underline;
        }
    }

    &_tabName {
        &_Returns {
            display: none;
        }
    }

    &.logout {
        margin-block-start: auto;

        button {
            border: 1px solid $default-primary-base-color;
            justify-content: center;
            border-radius: 4px;  
        }
    }
}
